import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import {
    CreateUserRequestDto,
    Employee,
    UpdateUserRequestDto,
    UserControllerService,
    UserDto,
} from 'app/api';
import { User } from 'app/core/user/user.types';
import { EmployeeService } from '../employees/employee.service';
import { UserService } from 'app/core/user/user.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingSerivce {
    currentUser: any;
    currentEmployee: WritableSignal<Employee> = signal(null);
    private _users$: WritableSignal<UserDto[]> = signal([]);

    get users(): Signal<UserDto[]> {
        return this._users$;
    }

    constructor(
        private api: UserControllerService,
        private userService: UserService,
        private employeeService: EmployeeService
    ) {}

    getCurrentUser(keycloakId: string): Observable<User> {
        return this.userService.user$;
    }

    async getAllUsers(orgId: string): Promise<void> {
        const users = await this.api.getUsers(orgId).toPromise();
        this._users$.set(users);
    }

    async resetPassword(pass: string, conf: string): Promise<void> {
        // this.api
        //     .resetPassword(this.currentUser.keycloakId, {
        //         password: pass,
        //         confirmPassword: conf,
        //     })
        //     .toPromise();
    }

    async updateUser(keycloakId: string, dto: UpdateUserRequestDto): Promise<void> {
        await this.api.updateUser(keycloakId, dto).toPromise();
    }

    async createUser(dto: CreateUserRequestDto): Promise<void> {
        await this.api.createUser(dto).toPromise();
        await this.employeeService.connectEmployeeToUser(dto.employee_id, dto);
    }
}
